import { alpha } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const useStyles = (theme) => ({
  container: {
    //minWidth: "340px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    // padding: "10px",
    gap: "1rem",
    height: "200px",
    // backgroundColor: "white",
  },
  paper: {
    width: "100%",
    minWidth: "320px",
    padding: 2,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  dataGrid: {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    maxHeight: "calc(100vh - 300px)",

    width: "100%",
    "&.MuiDataGrid-root .MuiDataGrid-cell": {
      display: "flex",
      alignItems: "center",
      p: 1,
      //justifyContent: "center",
      //height: "200px",
    },
  },
  boxFilters: {
    display: "flex",
    gap: "1rem",
    width: "100%",
    flexDirection: { xs: "column", smd: "row" },

    flexWrap: "wrap",
  },
  input: {
    width: "100%",
    display: "flex",
    flex: { xs: 1, smd: 2, lgd: "0 1 450px" },
    maxWidth: { xs: "100%", mg: "450px" },
  },
  inputStatus: {
    width: "100%",
    display: "flex",
    flex: { xs: 1, lgd: "0 1 180px" },
    // maxWidth: { xs: "100%", mg: "250px" },
  },
  inputModulos: {
    width: "100%",
    display: "flex",
    flex: { xs: 1, lgd: "0 1 450px" },
    // maxWidth: { xs: "100%", mg: "180px" },
  },
  boxChip: {
    display: "flex",
    gap: 0.5,
    flexWrap: "wrap",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
  },
  boxLastConnection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: { xs: "row", md: "column" },
    alignItems: { xs: "center", md: "flex-start" },
    //flexWrap: "wrap",
    gap: 0.5,
    height: "100%",
  },
  iconTextFields: { color: "text.secondary", fontSize: "18px" },
  noPlans: {
    backgroundColor: alpha(theme.palette.text.secondary, 0.1),
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    "&.MuiChip-root .MuiChip-label": {
      marginTop: "2px",
    },
  },
  chipUnknown: {
    backgroundColor: alpha(theme.palette.text.secondary, 0.1),
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    "&.MuiChip-root .MuiChip-label": {
      marginTop: "2px",
    },
  },
  chipActive: {
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    color: theme.palette.success.light,
    fontWeight: "bold",
    "&.MuiChip-root .MuiChip-label": {
      marginTop: "2px",
    },
  },
  alert: {
    backgroundColor: alpha(theme.palette.warning.main, 0.1),
    color: theme.palette.warning.dark,
    fontWeight: "bold",
    "&.MuiChip-root .MuiChip-label": {
      marginTop: "2px",
    },
  },
  block: {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    color: theme.palette.error.main,
    fontWeight: "bold",
    "&.MuiChip-root .MuiChip-label": {
      marginTop: "2px",
    },
  },
  columnDate: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 0.5,
    height: "100%",
  },

  textDrawerSubtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  textDrawerContent: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  jsonInput: {
    body: { "font-size": "0.85rem" },
  },
  boxHeaderLogs: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },
  titleLogs: { display: "flex", gap: 1, flexDirection: "column" },
  layout1: { display: "flex", gap: 2, flexDirection: "column" },
  layout2: { display: "flex", gap: 4 },
  boxEditor: {
    borderRadius: 2,
    border: "1px solid " + theme.palette.stroke.main,
    backgroundColor: alpha(theme.palette.background.editor, 1),
    p: 1,

    position: "relative",
  },
  iconCopy: {
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    top: 8,
    right: 8,
    position: "absolute",
    zIndex: 1,
  },
  paperDrawer: {
    backgroundColor: "drawer.chat",
    width: { xs: "100%", smd: "800px" },
    minWidth: "340px",
  },
});

export default useStyles;

import { alpha, Box, useTheme } from "@mui/material";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
  useTreeItem2,
} from "@mui/x-tree-view";
import clsx from "clsx";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomMenu from "../../customMenu";

const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
  const {
    id,
    itemId,
    label,
    link,
    icon,
    children,
    disabled,
    hideTitle = false,
    open = true,
    closeOnClickItem = false,
    setOpen = () => {},
    setSelectedItems = () => {},
    setExpandedItems = () => {},
    selectedItems = [],
    paternal = null,
    nodes = null,
    apiRef,
    titlePage = "",
    setTitlePage = () => {},
    ...other
  } = props;
  const theme = useTheme();
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    //getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    const pageName = window.location.pathname;
    if (link === pageName) {
      setSelectedItems(paternal ? [paternal.itemId, itemId] : [itemId]);
      setExpandedItems(paternal ? [paternal.itemId] : []);
      setTitlePage(hideTitle ? "" : label);
    } else if (nodes?.some((item) => item.link === pageName)) {
      setExpandedItems([itemId]);
    }
  }, [window.location.pathname, open]);

  const handleItemClick = (e) => {
    if (nodes && !paternal) {
      //se for um item pai
      //expande ou recolhe o item clicado
      setExpandedItems((oldIds) =>
        oldIds.includes(itemId)
          ? oldIds.filter((id) => id !== itemId)
          : [...oldIds, itemId]
      );
    } else {
      //Se o item clicado não tiver filhos
      if (open) setExpandedItems(paternal ? [paternal.itemId] : []); // Expande o pai ou recolhe todos os itens
      setSelectedItems(paternal ? [itemId, paternal.itemId] : [itemId]); // Seleciona o pai e o item clicado ou apenas o item clicado
      if (link) {
        if (closeOnClickItem) setOpen(false);
        navigate(link);
        setTitlePage(hideTitle ? "" : label);
      }
    }
  };
  const styles = {
    content: {
      display: "flex",
      marginY: "3px",
      marginX: 0,
      minHeight: "40px",
      height: "auto",
      color:
        status.selected && !!paternal
          ? theme.palette.rightBar.text
          : status.selected
          ? theme.palette.primary.main
          : status.expanded
          ? theme.palette.rightBar.text
          : theme.palette.rightBar.textDisabled,
      backgroundColor:
        status.selected && !!paternal
          ? alpha(theme.palette.text.primary, 0.1) //cor para o node filho que estiver selecionado
          : status.selected
          ? alpha(theme.palette.primary.main, 0.1) // cor para o node pai que estiver expandido
          : status.expanded
          ? alpha(theme.palette.text.primary, 0.1)
          : null,
      "&:hover": {
        backgroundColor:
          status.selected && !paternal
            ? alpha(theme.palette.primary.main, 0.2)
            : alpha(theme.palette.text.primary, 0.05),
      },
    },
    contentBox: {
      flexGrow: 1,
      display: "flex",
      gap: "10px",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    groupTransition: {
      marginLeft: 2,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
  };
  return (
    <>
      <TreeItem2Provider itemId={itemId}>
        <TreeItem2Root {...getRootProps(other)}>
          <TreeItem2Content
            {...getContentProps({
              className: clsx("content", {
                expanded: status.expanded,
                selected: status.selected,
                focused: status.focused,
              }),
            })}
            sx={styles.content}
            onClick={handleItemClick}
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            onMouseLeave={(e) => {
              setAnchorEl(null);
              apiRef?.current?.focusItem(e, selectedItems[0]);
            }}
          >
            <Box sx={styles.contentBox}>
              {icon}
              {open ? <TreeItem2Label {...getLabelProps()} /> : null}
            </Box>
            {open && (
              <TreeItem2IconContainer {...getIconContainerProps()}>
                <TreeItem2Icon status={status} />
              </TreeItem2IconContainer>
            )}
            <CustomMenu
              anchorEl={anchorEl}
              openMenu={openMenu && !open}
              setAnchorEl={setAnchorEl}
              {...props}
            />
          </TreeItem2Content>
          {children && open && (
            <TreeItem2GroupTransition
              {...getGroupTransitionProps()}
              sx={styles.groupTransition}
            />
          )}
        </TreeItem2Root>
      </TreeItem2Provider>
    </>
  );
});
export default CustomTreeItem;

import { alpha, createTheme, darken, lighten } from "@mui/material/styles";

const mainTheme = (colorMode) =>
  createTheme({
    palette: {
      mode: !colorMode ? "light" : "dark",
      primary: {
        main: !colorMode ? "#5B2E87" : "#A77AD2",
      },
      secondary: {
        main: "#e83750",
      },
      drawer: {
        chat: !colorMode ? "#FFFFFF" : "#000000",
      },
      background: {
        default: !colorMode ? "#F0F2F5" : "#141617",
        paper: !colorMode ? "#FFFFFF" : "#121212",
        paperAdm: !colorMode ? "#FFFFFF" : "#1E1E1E",
        paperChat: !colorMode ? "#F9FAFB" : "#353535",
        paperOutlined: !colorMode ? "#FFFFFF" : "#252525",
        paperNotification: !colorMode ? "#f1f1f1" : "#1d2021",
        preSelectedTreeview: !colorMode ? "#f1f1f1" : "#303030",
        textField: !colorMode ? "#E3E3E3 " : "#2F2F2F",
        editor: !colorMode ? "#f1f1f1" : "#1f1f1f",
      },
      stroke: {
        main: !colorMode ? "#E3E3E3" : "#2F2F2F",
      },
      text: {
        primary: !colorMode ? "#3C3C3C" : "#ECECEC",
        secondary: !colorMode ? "#757575" : "#BCBCBC",
        disabled: !colorMode ? "#9E9E9E" : "#878686",
      },
      filters: {
        main: !colorMode ? alpha("#842ED9", 0.1) : alpha("#842ED9", 0.3),
        cover: !colorMode ? alpha("#5B2E87", 0.1) : alpha("#A77AD2", 0.2),
        superAdmin: !colorMode ? alpha("#8c38c0", 0.1) : alpha("#c297eb", 0.1),
        admin: alpha("#FB8C00", 0.1),
        dev001: alpha("#42A5F5", 0.1),
        dev002: alpha("#66BB6A", 0.1),
        noc001: alpha("#27a9ba", 0.1),
        noc002: alpha("#f9627d", 0.1),
      },
      rightBar: {
        preSelected: !colorMode ? "#f1f1f1" : "#313131",
        selected: !colorMode ? "#EBE6F0" : "#2d2833",
        hoverSelected: !colorMode ? "#d8cde2" : "#3F3449",
        textDisabled: !colorMode ? "#3E4041" : "#BCBCBC",
        text: !colorMode ? "#141414" : "#FFFFFF",
      },
      chip: {
        pending: !colorMode ? "#E83750" : "#A61328",
        inProgress: !colorMode ? "#ED6C02" : "#BE5602",
        completed: !colorMode ? "#2E7D32" : "#387F4D",
        waitingReview: !colorMode ? "#0288D1" : "#077CB1",
        waitingReturn: !colorMode ? "#5B2E87" : "#502876",
        arquive: !colorMode ? "#777777" : "#424242",
      },
      iconsAcesso: {
        proritario: !colorMode ? "#0288D1" : "#077CB1",
        exigente: !colorMode ? "#E83750" : "#A61328",
        customizado: !colorMode ? "#ED6C02" : "#BE5602",
      },
      color: {
        textDisable: !colorMode ? "rgb(112, 110, 110)" : "rgb(112, 110, 110)",
      },
      sucess: {
        main: !colorMode ? "#04B55D" : "#1ACB73",
      },
      warning: {
        main: !colorMode ? "#ffc62b" : "#FDC52B",
        dark: !colorMode ? "#ffa115" : "#FDC52B",
      },
      error: {
        main: !colorMode ? "#FF4130" : "#FF5343",
      },
      info: {
        main: !colorMode ? "#0A80FF" : "#3F9DF4",
      },
      access: {
        superAdmin: !colorMode ? "#8c38c0" : "#c297eb",
        admin: !colorMode ? darken("#FB8C00", 0.1) : lighten("#f5a23d", 0.1), //: "#f5a23d",
        dev001: !colorMode ? darken("#42A5F5", 0.2) : lighten("#42A5F5", 0.2),
        dev002: !colorMode ? darken("#66BB6A", 0.2) : lighten("#66BB6A", 0.2),
        noc001: !colorMode ? darken("#27a9ba", 0.1) : lighten("#27a9ba", 0.2),
        noc002: !colorMode ? darken("#f9627d", 0.1) : lighten("#f9627d", 0.1),
      },
      modules: {
        easymaps: !colorMode ? "#ec4b60" : "#e83750",
        easybi: !colorMode ? "#13C06A" : "#1AD866",
        easyreport: !colorMode ? "#FB7500" : "#ED7B00",
        easylog: !colorMode ? "#ff0a0a" : "#ff0a0a",
        easymon: !colorMode ? "#E65835" : "#E65835",
        easyflow: !colorMode ? "#2DC989" : "#2DC989",
        combo: !colorMode ? "#5B2E87" : "#A77AD2",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        smd: 800,
        md: 900,
        mg: 1000,
        lg: 1200,
        lgd: 1300,
        xl: 1536,
        fh: 1920,
        qhd: 2560,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          //containedPrimary: { backgroundColor: "#5B2E87" },
          root: {
            textTransform: "capitalize",
            boxShadow: "none",
            borderRadius: "5px",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& ::placeholder": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputLabel-root": {
              fontSize: "1rem", // Tamanho do texto do label
              marginBottom: "5px",
            },
            "& .MuiInputBase-input": {
              fontSize: "1rem", // Tamanho do texto do placeholder
              display: "flex",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              boxShadow: "0px 0px 2px  #0000001a",
              backgroundColor: theme.palette.background.textField,

              "&.Mui-focused": {
                backgroundColor: "none",
                "& fieldset": {
                  border: `1px solid  ${theme.palette.text.disabled}`,
                },
                "&:hover": {
                  "& fieldset": {
                    border: `1px solid  ${theme.palette.text.disabled}`,
                  },
                },
              },

              "&:hover": {
                backgroundColor: theme.palette.background.textField,
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
              "&.Mui-disabled": {
                backgroundColor: alpha(theme.palette.background.paper, 0.2),
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover": {
                  "& fieldset": {
                    borderColor: "none",
                  },
                },
              },
              "&.Mui-error": {
                "& fieldset": {
                  border: `1px solid ${theme.palette.error.main}`,
                },
                "&:hover": {
                  "& fieldset": {
                    border: `1px solid  ${theme.palette.error.main}`,
                  },
                },
              },
            },
          }),
        },
        defaultProps: {
          //variant: "contained",
          size: "small",
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& .MuiTablePagination-selectLabel": {
              marginLeft: 1,
              maxWidth: "315px",
            },
            "& .MuiToolbar-root": {
              paddingLeft: 0,
              paddingRight: 0,
              gap: 0,
              minWidth: "fit-content",
            },
            "& .MuiTablePagination-select": {
              paddingLeft: 0,
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "-25px",
            },
          }),
        },
      },
    },
    mixins: {
      MuiDataGrid: {
        containerBackground: !colorMode ? "#FFFFFF" : "#1E1E1E",
      },
    },
  });

export default mainTheme;

import {
  Box,
  Paper,
  Chip,
  useTheme,
  alpha,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Autocomplete,
  Button,
  DialogTitle,
  Drawer,
  DialogContent,
  Divider,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import "dayjs/locale/pt-br";
import { useState, useEffect, Fragment, useRef } from "react";
import api from "../../../../services/api";
import {
  ArrowBack,
  ArrowForward,
  Article,
  Block,
  Close,
  ContentCopy,
  Done,
  Search,
  SearchOff,
  WarningRounded,
} from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import useStyles from "./styles";
import { ptBR } from "@mui/x-data-grid/locales";
import { useNotification } from "../../../../componentes/notificationProvider";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Conexoes({}) {
  //pagina

  const [clienteModulos, setClienteModulos] = useState([]);
  const [textoFiltro, setTextoFiltro] = useState("");
  const [modulosOptions, setModulosOptions] = useState([]);
  const [moduloFilter, setModuloFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [pageLogs, setPageLogs] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [logsConexaoSeleted, setLogsConexaoSeleted] = useState(null);
  const [clienteSelected, setClienteSelected] = useState(null);
  const [logsConexaoCliente, setLogsConexaoCliente] = useState([]);
  const notify = useNotification();
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();
  const { acessoClienteId } = useParams();
  const columnVisibility = {
    id: false,
  };
  const handleMapModules = (clienteModulos) => {
    const filterClients = clienteModulos.filter(
      (row) => row?.ModuloCliente?.modulos?.nomeFantasia
    );
    let planos =
      filterClients.length > 0 ? filterClients[0]?.ModuloCliente?.modulos : {};

    planos = Object.keys(planos);

    planos = planos.reduce((acc, plano) => {
      if (plano?.split("plano")[1] && "planoContrato" !== plano)
        acc.push({
          value: plano,
          nome: plano?.split("plano")[1],
        });
      return acc;
    }, []);
    planos = planos.sort((a, b) => a.nome.localeCompare(b.nome));

    return planos;
  };
  useEffect(() => {
    handleApiGetModulesByClient();
    if (acessoClienteId != "status") {
      setPageLogs(true);
      handleApiGetLogsConexaoCliente(acessoClienteId);
    }
  }, []);
  const colorStatusCode = (inCode) => {
    const code = parseInt(inCode);
    if (code >= 200 && code < 300) {
      return theme.palette.success.light;
    } else if (code >= 300 && code < 400) {
      return theme.palette.info.main;
    } else if (code >= 400 && code < 500) {
      return theme.palette.warning.dark;
    } else if (code >= 500) {
      return theme.palette.error.main;
    } else {
      return theme.palette.text.secondary;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "empresa",
      headerName: "Empresa",
      width: 150,
    },
    {
      field: "parceiro",
      headerName: "Parceiro",
      width: 150,
    },
    {
      field: "numero",
      headerName: "Número",
      width: 80,
    },
    {
      field: "planos",
      headerName: "Planos",
      minWidth: 500,
      flex: 1,
      align: "center",
      // sortable: false,
      valueGetter: (value, row) => (row?.ModuloCliente?.modulos ? true : false),
      renderCell: (params) => {
        return (
          <Box sx={styles.boxChip}>
            {!params.row.ModuloCliente?.modulos && (
              <Chip label="Sem planos" sx={styles.noPlans} />
              // <Typography>-</Typography>
            )}
            {modulosOptions.map(
              (plano) =>
                params.row?.ModuloCliente?.modulos[plano?.value] && (
                  <Chip
                    label={plano?.nome}
                    sx={{
                      backgroundColor: alpha(
                        theme.palette.modules[plano?.nome?.toLowerCase()] ||
                          theme.palette.grey[300],
                        0.1
                      ),
                      color:
                        theme.palette.modules[plano?.nome?.toLowerCase()] ||
                        theme.palette.grey[300],
                      fontWeight: "bold",

                      "&.MuiChip-root .MuiChip-label": {
                        marginTop: "2px",
                      },
                    }}
                  />
                )
            )}
          </Box>
        );
      },
      // valueGetter: (params) => `${params.getValue("ModuloCliente") || ""}`,
    },
    {
      field: "acessoModulos",
      headerName: "Acesso aos Modulos",
      description: "This column has a value getter and is not sortable.",
      width: 200,
      valueGetter: (value, row) => row?.ModuloCliente?.modulos?.status,
      renderCell: (params) => {
        return (
          <div>
            {params.row?.ModuloCliente?.modulos?.status === 2 ? (
              <Chip
                label="Bloqueado"
                icon={
                  <Block fontSize="small" color={theme.palette.error.main} />
                }
                sx={styles.block}
              />
            ) : params.row?.ModuloCliente?.modulos?.status === 1 ? (
              <Chip
                label="Em Alerta"
                icon={
                  <WarningRounded
                    fontSize="small"
                    color={theme.palette.warning.main}
                  />
                }
                sx={styles.alert}
              />
            ) : params.row?.ModuloCliente?.modulos?.status === 0 ? (
              <Chip
                label="Ativo"
                icon={<Done color={theme.palette.success.main} />}
                sx={styles.chipActive}
              />
            ) : (
              <Chip
                label="Desconhecido"
                icon={
                  <SearchOff
                    sx={{
                      color: theme.palette.text.secondary,
                    }}
                  />
                }
                sx={styles.chipUnknown}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "ultimaConexao",
      headerName: "Última Conexão",
      description: "This column has a value getter and is not sortable.",
      width: 200,
      valueGetter: (value, row) => row?.LogsConexaoCliente?.[0]?.createAt,
      renderCell: (params) => {
        const code = params.row?.LogsConexaoCliente?.[0]?.code;
        const time = params.row?.LogsConexaoCliente?.[0]?.createAt;

        return time ? (
          <Box sx={styles.boxLastConnection}>
            <Typography fontSize={13} sx={styles.textDate}>
              {handleTime(time)}
            </Typography>
            <Box sx={{ display: "flex", gap: "0.5rem", direction: "row" }}>
              <Typography fontSize={12} sx={styles.subTextDate}>
                {"Código de status: "}
              </Typography>
              <Typography
                fontSize={12}
                sx={{
                  color: colorStatusCode(code),
                  fontWeight: "bold",
                }}
              >
                {code}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography>-</Typography>
        );
      },
    },
    {
      field: "detalhesLogs",
      headerName: "",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              fontSize: 12,
            }}
            size="small"
            endIcon={<ArrowForward fontSize="small" />}
            variant="text"
            disabled={params.row?.LogsConexaoCliente?.length === 0}
            onClick={() => {
              setClienteSelected(params.row);
              setPageLogs(true);
              handleApiGetLogsConexaoCliente(params.row?.id);
              navigate(`/noc/clientes/sistemas/${params.row?.id}`);
            }}
          >
            Ver logs
          </Button>
        );
      },
    },
  ];
  const columnsLogs = [
    { field: "id", headerName: "ID", width: 90 },

    {
      field: "response",
      headerName: "Response",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const response =
          params.value === null ? "NULL" : JSON.stringify(params.value || {});
        return (
          <Typography
            sx={{
              fontSize: 14,
              color:
                response === "NULL"
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
            }}
          >
            {response.length > 100
              ? `${response.substring(0, 100)}...`
              : response}
          </Typography>
        );
      },
    },
    {
      field: "error",
      headerName: "Error",
      minWidth: 200,
      flex: 1,
      //valueGetter: (params) => (params.value ? true : false),
      renderCell: (params) => {
        const error =
          params.value === null ? "NULL" : JSON.stringify(params.value);
        return (
          <Typography
            sx={{
              color:
                error === "NULL"
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary,
            }}
          >
            {error.length > 100 ? `${error.substring(0, 100)}...` : error}
          </Typography>
        );
      },
    },
    {
      field: "code",
      headerName: "Código de Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              color: colorStatusCode(params.value),
              fontWeight: "bold",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "createAt",
      headerName: "Data",
      width: 150,
      renderCell: (params) => {
        return (
          params.value && (
            <Box sx={styles.columnDate}>
              <Typography fontSize={13} sx={styles.textDate}>
                {dayjs(params.value).locale("pt-br").format("D [de] MMM, YYYY")}
              </Typography>
              <Typography fontSize={12} sx={styles.subTextDate}>
                {dayjs(params.value).locale("pt-br").format("[às] HH:mm:ss")}
              </Typography>
            </Box>
          )
        );
      },
    },
    {
      field: "detalhes",
      headerName: "Detalhes",
      width: 90,
      renderCell: (params) => {
        return (
          <Tooltip title="Mostrar Detalhes" placement="bottom">
            <IconButton
              onClick={() => {
                setLogsConexaoSeleted(params.row);
                setOpenModal(true);
              }}
              sx={{ color: theme.palette.text.secondary }}
            >
              <Article />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  async function handleApiGetLogsConexaoCliente(acessoClientesId) {
    try {
      setLoadingLogs(true);
      const response = await api.post("/logs/conexao/cliente/findMany", {
        acessoClientesId,
      });

      if (response.data) {
        setLogsConexaoCliente(response.data);
      }
    } catch (error) {
      notify(error?.response?.data?.error, "error");
    } finally {
      setLoadingLogs(false);
    }
  }
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }
  async function handleApiGetModulesByClient(params) {
    try {
      setLoading(true);
      const response = await api.post("/modulo/cliente/findMany");

      if (response.data) {
        setClienteModulos(response.data);
        setModulosOptions(handleMapModules(response.data));
        if (acessoClienteId != "status") {
          setClienteSelected(
            response.data.find((cliente) => cliente.id == acessoClienteId)
          );
        }
      }
    } catch (error) {
      notify(error?.response?.data?.error, "error");
    } finally {
      setLoading(false);
    }
  }
  function Editor({ conteudo }) {
    try {
      const jsonEditorRef = useRef(null);
      const colorsStyles = {
        default: theme.palette.text.disabled,
        background_warning: "#FFDCDB",
        string: theme.palette.success.main,
        number: theme.palette.info.main,
        colon: "#7a5bac",
        keys: theme.palette.primary.main,
        primitive: theme.palette.secondary.main,
        background: "transparent",
      };
      const copyToClipboard = () => {
        if (jsonEditorRef.current) {
          const jsonString = JSON.stringify(conteudo, null, 2);
          navigator.clipboard.writeText(jsonString).then(() => {
            notify("Conteúdo copiado para a área de transferência!", "info");
          });
        }
      };
      return (
        <Box sx={styles.boxEditor}>
          <IconButton
            onClick={copyToClipboard}
            sx={styles.iconCopy}
            size="small"
          >
            <ContentCopy />
          </IconButton>
          <JSONInput
            ref={jsonEditorRef}
            id="a_unique_id"
            placeholder={
              conteudo &&
              typeof conteudo === "object" &&
              Object.keys(conteudo)?.length > 0
                ? conteudo
                : {}
            }
            locale={locale}
            style={styles.jsonInput}
            colors={colorsStyles}
            viewOnly={true}
            onchange
            height="100%"
            width="100%"
            confirmGood={false}
          />
        </Box>
      );
    } catch (error) {
      <></>;
    }
  }
  const filteredRows = clienteModulos.filter((row) => {
    const empresa = row?.empresa?.toLowerCase();
    const parceiro = row?.parceiro?.toLowerCase();
    const numero = row?.numero?.toString().toLowerCase();
    const modulos = row?.ModuloCliente?.modulos;
    const status = row?.ModuloCliente?.modulos?.status;

    return (empresa?.includes(textoFiltro?.toLowerCase()) ||
      parceiro?.includes(textoFiltro?.toLowerCase()) ||
      numero?.includes(textoFiltro?.toLowerCase())) &&
      statusFilter != null
      ? status === statusFilter.value
      : true &&
          (moduloFilter.length > 0
            ? modulos && moduloFilter.some((modulo) => modulos[modulo?.value])
            : true);
  });
  return (
    <Fragment>
      <Box sx={styles.container}>
        <Paper sx={styles.paper}>
          {pageLogs ? (
            <>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Tooltip title="Voltar" placement="bottom">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setPageLogs(false);
                      setClienteSelected(null);
                      setLogsConexaoSeleted(null);
                      navigate("/noc/clientes/sistemas/status");
                    }}
                    variant="contained"
                    sx={{
                      alignSelf: "flex-start",
                      mt: -0.5,
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                </Tooltip>

                <Box sx={styles.layout1}>
                  <Typography>
                    Logs de conexão do cliente{" "}
                    <b>
                      {clienteSelected?.empresa} - {clienteSelected?.numero}
                    </b>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Planos:{" "}
                    {modulosOptions.map(
                      (plano) =>
                        clienteSelected?.ModuloCliente?.modulos[
                          plano?.value
                        ] && (
                          <Chip
                            label={plano?.nome}
                            sx={{
                              mr: 0.5,
                              backgroundColor: alpha(
                                theme.palette.modules[
                                  plano?.nome?.toLowerCase()
                                ] || theme.palette.grey[300],
                                0.1
                              ),
                              color:
                                theme.palette.modules[
                                  plano?.nome?.toLowerCase()
                                ] || theme.palette.grey[300],
                              fontWeight: "bold",

                              "&.MuiChip-root .MuiChip-label": {
                                marginTop: "2px",
                              },
                            }}
                          />
                        )
                    )}{" "}
                  </Typography>
                </Box>
              </Box>

              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowHeight={() => "auto"}
                sx={styles.dataGrid}
                columnVisibilityModel={columnVisibility}
                columns={columnsLogs}
                onRowClick={(params) => {
                  setLogsConexaoSeleted(params.row);
                  setOpenModal(true);
                }}
                rows={logsConexaoCliente}
                align="center"
                loading={loadingLogs}
              />
            </>
          ) : (
            <>
              <Box sx={styles.boxFilters}>
                <TextField
                  value={textoFiltro}
                  variant="outlined"
                  onChange={(e) => {
                    setTextoFiltro(e.target.value);
                  }}
                  sx={styles.input}
                  placeholder="Filtrar por empresa, parceiro ou número"
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <Search sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  sx={styles.inputModulos}
                  options={modulosOptions}
                  getOptionLabel={(option) => option?.nome}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.nome}
                      </li>
                    );
                  }}
                  value={moduloFilter}
                  onChange={(event, newValue) => {
                    setModuloFilter(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filtrar planos"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  sx={styles.inputStatus}
                  options={[
                    { value: 0, nome: "Ativo" },
                    { value: 1, nome: "Em Alerta" },
                    { value: 2, nome: "Bloqueado" },
                  ]}
                  getOptionLabel={(option) => option?.nome}
                  value={statusFilter}
                  onChange={(event, newValue) => {
                    setStatusFilter(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Filtrar acesso ao modulos"
                      variant="outlined"
                    />
                  )}
                />
              </Box>

              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                // autoHeight={true}
                getRowHeight={() => "auto"}
                sx={styles.dataGrid}
                columnVisibilityModel={columnVisibility}
                columns={columns}
                rows={filteredRows}
                align="center"
                loading={loading}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "planos", sort: "desc" }],
                  },
                }}
              />
            </>
          )}
        </Paper>
      </Box>
      <Drawer
        open={openModal}
        onClose={() => setOpenModal(false)}
        anchor="right"
        PaperProps={{
          sx: styles.paperDrawer,
        }}
      >
        <DialogTitle sx={styles.boxHeaderLogs}>
          <IconButton onClick={() => setOpenModal(false)}>
            <Close />
          </IconButton>
          <Typography variant="h6">Detalhes do Log de Conexão</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={styles.layout1}>
          <Box sx={styles.layout2}>
            <Box sx={styles.layout1}>
              {" "}
              <Typography sx={styles.textDrawerSubtitle}>
                {"Código de Status: "}
              </Typography>{" "}
              <Typography sx={styles.textDrawerSubtitle}>
                {"Data de Criação: "}
              </Typography>
            </Box>
            <Box sx={styles.layout1}>
              <Typography
                sx={{
                  ...styles.textDrawerContent,
                  color: colorStatusCode(logsConexaoSeleted?.code),
                  fontWeight: "bold",
                }}
              >
                {logsConexaoSeleted?.code}
              </Typography>
              <Typography sx={styles.textDrawerContent}>
                {dayjs(logsConexaoSeleted?.createAt)
                  .locale("pt-br")
                  .format("D [de] MMM, YYYY") +
                  dayjs(logsConexaoSeleted?.createAt)
                    .locale("pt-br")
                    .format(" [às] HH:mm:ss")}
              </Typography>
            </Box>
          </Box>

          {logsConexaoSeleted?.response && (
            <Box sx={styles.layout1}>
              <Typography sx={styles.textDrawerSubtitle}>
                {"Response: "}
              </Typography>
              <Editor conteudo={logsConexaoSeleted?.response} />
            </Box>
          )}
          {logsConexaoSeleted?.error && (
            <Box sx={styles.layout1}>
              <Typography sx={styles.textDrawerSubtitle}>
                {"Error: "}
              </Typography>
              <Editor conteudo={logsConexaoSeleted?.error} />
            </Box>
          )}
        </DialogContent>
      </Drawer>
    </Fragment>
  );
}
